.pgform-title-eam {
  width : 100%;
  align-content: center;
  justify-content: center;
  text-align: center;
  font: normal normal 600 18px Segoe UI;
  letter-spacing: 0px;
  padding-top: 1%;
  padding-bottom: 1%;
}

.form-eam {
    width: 100%;
}

/* .input-field {
    margin-bottom: 27px;
    margin-bottom: 0;
} */

.label-container-eam{
  margin-bottom: 8px;  
  display: flex;
  align-items: center;
}

.error-label-eam {
  color: red;
    font-size: 16px;
    margin-top: 5px;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

.pgBuddySubmit-buttons-container-eam {
  display: flex;
  justify-content:space-between;
  margin-top: 1%;
  padding-bottom: 1%;
  padding-left: 4%;
  padding-right:4%;
}  

.MuiFormControl-root {
  width: 100%;
}

.MuiTextField-root {
  margin-bottom: 0px;
}

.MuiButton-root {
  margin-right: 10px;
}

/* .MuiInputBase-root{
  height : 10px;
} */

input.MuiInputBase-input {
  height: 13px;
  padding: 16.5px 14px !important;
}

textarea.MuiInputBase-input {
  height: 120px !important;
}

.MuiFormControlLabel-label {
  font: normal normal normal 16px Segoe UI !important;
  letter-spacing: 0px !important;
  color: #3B3B3B !important;
}


  @media screen and (min-width: 732px) {
    /* CSS styles for width greater than 731px */
    .richtext-containear-pgbuddy-eam{
        max-width: calc(111.11vw - 10rem) !important
      }
  }

  /* For width less than 731px */
  @media screen and (max-width: 731px) {
    /* CSS styles for width less than 731px */
    .richtext-containear-pgbuddy-eam{
        max-width: calc(111.11vw - 8rem) !important
      }
  }
  .helper-text-eam {
    text-align: right;
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }
  
.input-label-eam {
  font: normal normal 600 16px Segoe UI;
  margin-bottom: 0.5rem;
  display: block;
  letter-spacing: 0px;
}

.react-select__control {
  margin-top: 0.5rem;
}


.helper-text-container-eam {
  margin-top: 4px;
  margin-bottom: 0px;
}


.helper-text-containear-eam {
  margin-top: 4px;
  margin-bottom: 0;
}

.MuiGrid-item {
  margin-bottom: 0px !important;  
}

.richtext-containear-pgbuddy-eam {
  margin-bottom: 8px;
}


.MuiGrid-item:last-child {
  margin-bottom: 0 !important;
}


.quill {
  margin-bottom: 5px;
}