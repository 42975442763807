.pgform-title {
  width : 100%;
  align-content: center;
  justify-content: center;
  text-align: center;
  font: normal normal 600 18px Segoe UI;
  letter-spacing: 0px;
  padding-top: 1%;
  padding-bottom: 1%;
}

.searchBar {
    display: flex;
    align-items: center;
    margin-bottom : 40px;
}

.search-btn {
    display: inline-block;
    border: 1px solid #ccc;
    background-color: #2088FF;
    font: normal normal normal 16px Segoe UI;
    margin-top: 23px;
    letter-spacing: 0px;
    color: #FFFFFF;
    width: 8%;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
    line-height: normal;
    text-align: center;
    margin-left: 1%
}

.search-btn:hover {
    /* background-color: #e0e0e0; */
}

.pgSearchContainer {
    display: flex;
    align-items: center;
    border: 2px solid;
    border-color: #A7A7A7;
    border-radius: 2px;
    padding: 8px;
    height: 25px;
    margin-top: 23px;
    width: 50%
}

.searchInput {
    flex: 1;
    margin-left: 8px;
}

.search-icon {
    color: #2088FF;
}

.form {
    width: 100%;
}

.checkbox-group{
    display: flex;
    flex-direction: column;
}

.form-container {
    display: flex;
    justify-content: space-between;
    padding-left: 6%;
}

.column {
    flex: 1;
    margin-right: 2%;
}

.right-column {
    margin-left: 3%;
    padding-top: 0.4%;
}

.input-field {
    margin-bottom: 27px;
}

.input-label {
    font: normal normal 600 16px Segoe UI;
    letter-spacing: 0px;
}
.input-field-with-help-text {
    margin-bottom: 5px;
    width: 100%;
  }

.required-label{
    color : red
}

.label-container {
    margin-bottom: 16px;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

.pgBuddySubmit-buttons-container {
  display: flex;
  justify-content:space-between;
  padding-bottom: 1%;
  padding-left: 4%;
  padding-right:4%;
}

/* Additional styles for form elements (adjust as per your requirements) */
.MuiFormControl-root {
    width: 100%;
}

.MuiTextField-root {
    margin-bottom: 10px;
}

.MuiButton-root {
    margin-right: 10px;
}

/* .MuiInputBase-root{
    height : 10px;
  } */

input.MuiInputBase-input {
    height: 13px;
    padding: 16.5px 14px !important;
}

textarea.MuiInputBase-input {
    height: 120px !important;
}

textarea {
    height: 13px;
}

.MuiFormControlLabel-label {
    font: normal normal normal 16px Segoe UI !important;
    letter-spacing: 0px !important;
    color: #3B3B3B !important;
}

.radio-button-margin {
    margin-right: 50px !important;
}

.dateDiv {
    display: flex;
    width: 100%;
}

/* file upload*/
.dropzone {
    border: 1px solid #CECECE;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    justify-content: center;
    cursor: pointer;
  }
.pgBuddyDropZone{
    width : 80%;
}
  .file-name{
    border : none;
    width:80%;
    font: normal normal 600 16px Segoe UI;
    letter-spacing: 0px;
   color: #3B3B3B;
  }

  .file-name::placeholder{
    font: normal normal normal 16px Segoe UI;
    letter-spacing: 0px;
   color: #3B3B3B;
  }
  
  .drag-drop-file-upload{
    display : flex;
    align-items: center;
  }

  .upload-btn {
    display: inline-block;
    border: 1px solid #ccc;
    background-color: #2088FF;
    font: normal normal normal 16px Segoe UI;
    letter-spacing: 0px;
    color: #FFFFFF;
    width: 18%;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
    line-height: normal;
    text-align: center;
    margin-left:1%
  }
  
  .upload-btn:hover {
    /* background-color: #e0e0e0; */
  }
  
  .file-name:hover,
.file-name:focus {
  border: none;
  outline: none;
  /* Add any other styles you want for the hover and focus states */
}

/* pop up overlay info */

.opportunity-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .opportunity-popup {
    background-color: #fff;
    max-width: 500px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .opportunity-popup-header {
    display: flex;
    justify-content: flex-end;
    background-color: #00000029;
  }
  
  .opportunity-popup-content {
    padding: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 12px;
  }

  .opportunity-popup-text {
    margin-left: 16px;
  }
  
  .opportunity-popup-text p {
    margin-bottom: 12px;
  }
  .disable-upload{
    opacity: 0.5;
  }

  .poniter-event-none{
    pointer-events: none;
  }
  
  @media screen and (min-width: 732px) {
    /* CSS styles for width greater than 731px */
    .richtext-containear-pgbuddy{
        max-width: calc(111.11vw - 10rem) !important
      }
  }
  
  /* For width less than 731px */
  @media screen and (max-width: 731px) {
    /* CSS styles for width less than 731px */
    .richtext-containear-pgbuddy{
        max-width: calc(111.11vw - 8rem) !important
      }
  }
  
  

