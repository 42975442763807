.bodyContainer {
    min-height: 111.11vh;
    display: flex;
    flex-direction: column;
}
.skip-note {
    font: normal normal 13px Segoe UI;
    letter-spacing: 0px;
    color: #3B3B3B;
    width: 100%;
}
.bolder{
    font-weight: 600;
}
.pageContainer {
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.navbar-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    margin-bottom: 50%;
}

/* wizard */
.wizardBody {
    padding: 3rem;
    bottom: 3rem;
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.wizardTab {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-around;
    margin-top: -2rem;
    align-items: center;
    background-color: white;
}

.contentPage {
    border: 1px solid #C9C9C9;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;

}

.modelCircle {
    background-color: #E5E5E5;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.2rem;
    border-radius: 50%;
}

.activeCircle {
    /* background-image: linear-gradient(180deg, #96ffe5, #63CCFF, #00ADEF); */
    background-color: #00ADEF;
    color: white;
}

.stepone {
    margin-left: -9rem;
    width: 12rem;
    display: flex;
    align-items: center;
}

.steptwo {
    margin-left: -0.5rem;
    width: 12rem;
    text-align: center;
    display: flex;
    align-items: center;
}

.stepthree {
    margin-right: -3.7rem;
    width: 12rem;
    text-align: center;
    display: flex;
    align-items: center;
}

.stepfour {
    margin-right: -12rem;
    width: 12rem;
    text-align: center;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 480px) {
    .stepone {
        margin-left: -5rem;
        display: flex;
        align-items: center;
        display: flex;
        align-items: center;
    }

    .stepfour {
        margin-right: -5rem;
        display: flex;
        align-items: center;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .addModel {
        width: 80%;
    }

    .stepone {
        margin-left: -10rem;
        width: 12rem;
        display: flex;
        align-items: center;
    }

    .steptwo {
        width: 12rem;
        display: flex;
        align-items: center;
    }

    .stepthree {
        width: 12rem;
        display: flex;
        align-items: center;
    }

    .stepfour {
        margin-right: -4rem;
        width: 12rem;
        display: flex;
        align-items: center;
    }
}

.step-label {
    display: flex;
    flex-direction: row;
    color: #656565;
    font: normal normal medium 16px Poppins;
    letter-spacing: 1px;
}

.transparent-div {
    background-color: transparent !important;
    padding-top: 18px !important;
}

.topic-heading {
    color: #0D0D0D;
    font: normal normal medium 16px Poppins;
    letter-spacing: 1px;
}