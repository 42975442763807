.form {
  width: 100%;
}

.form-container {
  display: flex;
  justify-content: space-between;
  padding-left: 6%;
}

.more-info-link {
  padding-left: 10px;
  font: normal normal normal 14px Segoe UI;
  letter-spacing: 0px;
  color: blue;
  text-decoration: underline;
}

.column {
  flex: 1;
  margin-right: 2%;
}

.right-column {
  margin-left: 3%;
  padding-top: 1.8%;
}

.tooltip-icon-button {
  color: '#00ADEF' !important;
}

.tooltip-icon {
  height: 20px !important;
  width: 20px !important;
}

.tooltip-background {
  background-color: '#00ADEF' !important
}

.helper-text-containear {
  display: flex;
  justify-content: flex-end;
}

.helper-text {
  text-align: right;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.input-field {
  margin-bottom: 27px;
}

.input-label {
  font: normal normal 600 16px Segoe UI;
  letter-spacing: 0px;
}

.label-container {
  margin-bottom: 16px;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.ql-editor {
  height: 150px !important;
  white-space: normal;
}

.ql-container {
  /* Set the desired width in percentage */
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;

}

.objective-buttons-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1%;
  padding-left: 4%;
  padding-right: 4%;
}

/* Additional styles for form elements (adjust as per your requirements) */
.MuiFormControl-root {
  width: 100%;
}

.MuiTextField-root {
  margin-bottom: 10px;
}

.MuiButton-root {
  margin-right: 10px;
}

/* .MuiInputBase-root{
    height : 10px;
  } */

input.MuiInputBase-input {
  height: 13px;
}

textarea.MuiInputBase-input {
  height: 120px !important;
}

textarea {
  height: 13px;
}

.MuiFormControlLabel-label {
  font: normal normal normal 16px Segoe UI !important;
  letter-spacing: 0px !important;
  color: #3B3B3B !important;
}

.radio-button-margin {
  margin-right: 50px !important;
}

.dateDiv {
  display: flex;
  width: 100%;
}

.dropzone {
  border: 1px solid #CECECE;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  width: 50%;
}

.file-name {
  border: none;
  width: 80%;
  font: normal normal 600 16px Segoe UI;
  letter-spacing: 0px;
  color: #3B3B3B;
}

.file-name::placeholder {
  font: normal normal normal 16px Segoe UI;
  letter-spacing: 0px;
  color: #3B3B3B;
}

.drag-drop-file-upload {
  display: flex;
  align-items: center;
}

.upload-btn {
  display: inline-block;
  border: 1px solid #ccc;
  background-color: #2088FF;
  font: normal normal normal 16px Segoe UI;
  letter-spacing: 0px;
  color: #FFFFFF;
  /* padding: 8px 16px; */
  /* cursor: pointer; */
  /* height: 45px; */
  width: 18%;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: center;
  line-height: normal;
  text-align: center;
  margin-left: 1%
}

.upload-btn:hover {
  /* background-color: #e0e0e0; */
}

/* pop up overlay info */

.opportunity-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.opportunity-popup {
  background-color: #fff;
  max-width: 500px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.opportunity-popup-header {
  display: flex;
  justify-content: flex-end;
  background-color: #00000029;
}

.opportunity-popup-content {
  padding: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 12px;
}

.opportunity-popup-text {
  margin-left: 16px;
}

.react-quill {
  width: 100% !important;

}

.richtext-containear {
  max-width: calc(111.11vw - 10rem) !important
}

.disable-upload {
  opacity: 0.5;
}

.opportunity-popup-text p {
  margin-bottom: 12px;
}

.poniter-event-none {
  pointer-events: none;
}