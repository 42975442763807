.navbar {
    display: flex;
    align-items: center;
    /* padding: 10px; */
    background-color: #F0F0F0;
    padding-top: 10px;
    padding-bottom : 10px;
    position: sticky;
    z-index: 100;
  }
  
  .logo {
    margin-right: 10px;
    height : 30px;
    margin-left : 35px;
  }
  
  .spacer {
    flex-grow: 1;
  }
  
  .user-info {
    display: flex;
    align-items: center;
    margin-right: 25px;
  }
  
  .avatar {
    width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #AC31FF;
  color: #FFFFFF;
  /* font-weight: bold;
  font-size: 18px; */
  font: normal normal 600 16px Segoe UI;
  margin-right: 30px;
  }
  
  .user-name {
    margin-bottom: 2px;
    font:normal normal 600 16px Segoe UI;
    letter-spacing: 0px;
    color: #1B1919;
    opacity: 1;
  }
  
  .user-role {
    font: normal normal normal 12px Segoe UI;
    letter-spacing: 0px;
    color: #606060;
    opacity: 1;
  }
  
  .logout-button {
    background-color: transparent;
    border: none;
    width: 26px;
    height: 26px;
    margin-right : 33px;
  }

  .viewPortPage{
    min-height: 111.11vh;
    display: flex;
    flex-direction: column;
  }

  .pageContent {
    flex-grow: 1;
    overflow-y: auto;
  }

  /* signout css */
  .signout-popup {
    position: absolute;
    top: 60px;
    right: 0;
    width: 250px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
  }
  
  .signout-popup-content {
    display: flex;
    flex-direction: column;
  }
  
  .user-info {
    margin-bottom: 10px;
  }
  
  .signout-button {
    background-color: #2088ff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .signout-link {
    font: normal normal normal 12px Segoe UI;
    letter-spacing: 0px;
    color: #606060;
    opacity: 1;
    margin-top: 5px;
    color: rgb(122, 122, 246);
    text-decoration: underline;
  }

  .signout-link:hover {
    color: blue;
    text-decoration: underline;
  }
  