.form{
    width: 100%;
}
.form-container {
    display: flex;
    justify-content: space-between;
    padding-left: 6%;
  }
  .react-select__menu{
    z-index: 100 !important;
  }
  .column {
    flex: 1;
    margin-right: 2%;
  }

  .radio-button-label-containear{
   margin-bottom: 3px;
  }

  .Mui-disabled{
    background-color: rgba(224, 224, 224, 0.3) !important; 
    border: 0 !important;
  }

  .disable-rich-text{
    background-color: rgba(224, 224, 224, 0.3) !important; 
    border: 0 !important;
  }
  .right-column{
    margin-left : 3%;
    padding-top: 0.4%;
  }
  
  .input-field {
    margin-bottom: 27px;
  }

  .input-field-with-help-text {
    margin-bottom: 5px;
    width : 100%;
  }

  .input-field-with-help-text .ReactQuill {
    width: 100%; /* Set the width of the ReactQuill component to 100% */
    max-width: 100%; /* Ensure it doesn't exceed the parent's width */
    /* Add any other styles for the ReactQuill component */
  }

  .error-note-speaker{
    /* margin-top : -32px; */
    margin-bottom: 7px;
    color:red;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }

  .date-note{
    margin-top : -28px;
    margin-bottom: 7px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
  }
  
  .input-label {
    font: normal normal 600 16px Segoe UI;
    letter-spacing: 0px;
  }
  .label-container-with-info-icon{
    margin-bottom: 12px !important;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .details-buttons-container {
    display: flex;
    justify-content:space-between;
    padding-bottom: 1%;
    padding-left: 4%;
    padding-right:4%;
  }
  
  /* Additional styles for form elements (adjust as per your requirements) */
  .MuiFormControl-root {
    width: 100%;
  }
  
  .MuiTextField-root {
    margin-bottom: 10px;
  }
  
  .MuiButton-root {
    margin-right: 10px;
  }

  /* .MuiInputBase-root{
    height : 10px;
  } */

  input.MuiInputBase-input{
    height : 13px;
  }

  textarea.MuiInputBase-input{
    height : 120px !important;
  }

  textarea{
    height : 13px;
  }

  .MuiFormControlLabel-label{
    font: normal normal normal 16px Segoe UI !important;
    letter-spacing: 0px !important;
    color: #3B3B3B !important;
  }
  .radio-button-margin{
    margin-right: 50px !important;
  }

  .dateDiv{
    display: flex;
    width: 100%;
  }

  .tooltip-icon-button{
    color : '#00ADEF' !important;
  }

  .tooltip-icon{
    height: 20px !important;
    width: 20px !important;
  }

  .tooltip-background{
    background-color : '#00ADEF' !important
  }

  .meeting-with-sponser{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  /* HelperText */
  .MuiFormHelperText-root{
    text-align : right !important
  }
