/* left section css */
.leftSection {
  padding-top: 31.5px;
  padding-left: 7.2%;
  padding-right: 7.2%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1 !important;
}

.leftSectionContainer {
  background-color: #f7f7f7;
  flex-grow: 1 !important;
  min-height: calc(111.11vh - 44px);
  height : auto;
  display: flex !important;
  flex-direction: column !important;
}
.accessLinks {
  padding-left: 7.2%;
  padding-right: 7.2%;
  margin-bottom : 20px;
  font: normal normal normal 14.4px Segoe UI;
  letter-spacing: 0px;
  color: #3b3b3b;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.title {
  font: normal normal 600 18px Segoe UI;
  letter-spacing: 0px;
  color: #0f0f0f;
  opacity: 1;
  margin-bottom: 19.8px;
}

.subtitle {
  font: normal normal normal 14.4px Segoe UI;
  letter-spacing: 0px;
  color: #3b3b3b;
  opacity: 1;
  margin-bottom: 31.5px;
}

.pageContainer {
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.bodyContainer {
  min-height: 111.11vh;
  display: flex;
  flex-direction: column;
}

.gridContainer {
  background-color: #f7f7f7;
  height: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex: 1;
}

.table-contaier{
  overflow-x: auto;
}
.table{
  width: 100%;
}
.tableHeading {
  background-color: #00adef;
  color: #ffffff !important;
  font: normal normal 600 14.4px Segoe UI !important;
  padding-left: 1% !important;
  text-align: center;
  padding-top:10px;
  padding-bottom:10px;
}
.headerCellContent {
  white-space: nowrap; /* Prevent text from wrapping to new lines */
  font: normal normal 600 14.4px Segoe UI !important;
}
.tableRow {
  height: 37.8px !important;
  color: #cccccc;
  margin-bottom: 10px !important;
}

.td {
  font: normal normal normal 14.4px Segoe UI !important;
  letter-spacing: 0px !important;
  color: #5c5c5c !important;
  opacity: 1 !important;
  margin-bottom: 10px !important;
}

.tableid:hover{
  color: blue !important;
  text-decoration: underline;
  cursor: pointer;
}

.tableCell {
  padding-top: 0px !important;
  padding-bottom: 5px !important;
  /* border-bottom: none !important; */
}

.table {
  border-collapse: separate !important;
  border-spacing: 0 !important;
  outline: 1.8px solid #000000 !important;
}

.eyeIcon {
  height: 18px !important;
  color: #2088ff;
}

.new-application-note{
  font: normal normal normal 14.4px Segoe UI;
  letter-spacing: 0px;
  color: #3b3b3b;
  text-align : center;
  color: #00B300;
  font-weight: 600;
  margin-bottom: 10px;
}

.view-all-link {
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 45px;
  margin-left: 0.9%;
}

/* right section css */

.rightSectionContainer {
  min-height: calc(111.11vh - 44px);
  flex-grow: 1 !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  background-color: #FFFFFF;
}

.rightSection {
  height: 100%;
  padding-top: 22.5px;
  padding-left: 7.2%;
  padding-right: 10.8%;
  padding-bottom: 49px;
}
.strategic-title{
  width: 100%;
    align-content: center;
    justify-content: center;
    text-align: center;
    font: normal normal 600 18px Segoe UI;
    letter-spacing: 0px;
    padding-top: 1%;
    padding-bottom: 1%;
}
.content-title {
  font: normal normal 600 18px Segoe UI;
  margin-bottom: 14.4px;
}

.content-subtitle {
  margin-bottom: 14.4px;
  font: normal normal normal 14.4px Segoe UI;
  letter-spacing: 0px;
  color: #3B3B3B;
}

.card {
  display: flex;
  align-items: center;
  margin-bottom: 14.4px;
  padding: 4.5px;
  border-left: 7.2px solid #00ADEF;
  background-color: #f7f7f7 !important;
  border-bottom: none;
  box-shadow: none;
  cursor: pointer;
}

.card-image {
  width: 20px !important;
  margin-right: 2.7%;
  margin-left: 2.7%;
}

.card-content {
  flex: 1;
}

.card-title {
  font: normal normal 600 18px Segoe UI;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 7.2px;
}

.card-subtitle {
  font: normal normal normal 14.4px Segoe UI;
  letter-spacing: 0px;
  color: #3B3B3B;
}

/* popup css */
.popup-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14.4px;
}

.popup-close-icon {
  padding: 3.6px;
}

.popup-subtitle {
  font-size: 14.4px;
  color: black;
  margin-bottom: 18px;
}

.popupSubmit{
  background-color: #2088FF;
  height : 36px;
  width : 117px;
  border : none;
  font: normal normal normal 14.4px Segoe UI;
  letter-spacing: 0px;
  color: #FFFFFF;
  margin-bottom: 14.4px;
  margin-right: 14.4px;
}

.popupHeadingDevider{
  margin-left : 7.2%;
  margin-right: 7.2%;
}

.popupDialog{
  padding-left: 7.2%;
  padding-top: 7.2%;
}

.my-request-containear{
  max-height: 360px;
  overflow-y: auto;
}

.warning-icon{
    color: yellow;
    font-size: 30px;
    filter: drop-shadow(0 0 2px black);
    padding-bottom: 0px;
    padding-right: 5px;
}

/* scrollbar css */
/* Hide the scrollbar arrows */

/*ebc warning popup*/

.ebc-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ebc-popup {
  background-color: #fff;
  max-width: 420px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.ebc-popup-content {
  padding: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.custom-line-height { /* You can adjust this value to increase or decrease the line spacing */
  /* font: normal normal normal 14px Segoe UI; */
  line-height: 1.7;
}
