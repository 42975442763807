.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.custom-input-table {
  height: 20px;
  padding: 5px;
  font-family: Segoe UI;
  border: 1px solid #888888;
  border-radius: 5px;
}

.input-container {
  display: flex;
  align-items: center;
  margin: 0px;
  padding: 0px;
}

.custom-input-table {
  padding: 5px;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  flex-grow: 1;
}

.avatar-container {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.avatar-icon {
  width: 23px;
  height: 23px;
  background-color: #4b81ff;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  margin-left: 7px;
}

.select-container-table {
  height: 32px;
  width: 170px;
  padding: 5px;
  font-family: Segoe UI;
  min-height: 20px;
  color: #888888;
  border: 1px solid #7c7d80;
  border-radius: 5px;
}

table.auto-width td {
white-space: nowrap;
font-size: 14px;
font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
color: #202020;
width: 100%;
overflow:hidden;
text-overflow:ellipsis;
overflow:hidden;
white-space:nowrap;
}

table.auto-width th {
white-space: nowrap;
font-size: 14px;
font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
color: #000000;
width: 100%;
overflow:hidden;
text-overflow:ellipsis;
overflow:hidden;
white-space:nowrap;
font-weight: 500;
Background-color: #e9e9e9;
}