.review-form {
    width: 84%;
    margin-left: 8%;
    margin-right: 8%;
}

.details-div {
    display: flex;
    padding-top: 20px;
}

.title-review {
    font: normal normal bold 22 Segoe UI;
    letter-spacing: 0px;
    color: #0F0F0F;
    background-color: #00adef;
    color: #ffffff !important;
    padding-left: 1% !important;
    padding-top:10px;
    padding-bottom:10px;
    margin-bottom: 10px !important;
}

.details-label {
    font: normal normal 600 16px Segoe UI;
    letter-spacing: 0px;
    color: #0A0A0A;
    margin-right: 10px
}

.details-value {
    font: normal normal normal 16px Segoe UI;
    letter-spacing: 0px;
    color: #3B3B3B;
}

.buttons-container {
    width: 100%;
    display: flex;
    justify-content:space-between;
    padding-bottom: 1%;
    padding-left: 0px;
    padding-right: 0px;
  }


  @media screen and (min-width: 732px) {
    /* CSS styles for width greater than 731px */
    .rich-text-wrap{
      max-width: calc(111.11vw - 28rem) !important;
      overflow-wrap: break-word !important
    }
  }
  
  /* For width less than 731px */
  @media screen and (max-width: 731px) {
    /* CSS styles for width less than 731px */
    .rich-text-wrap{
      max-width: calc(111.11vw - 23rem) !important;
      overflow-wrap: break-word !important
    }
  }

  /* popup settings */
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup {
    background-color: #fff;
    max-width: 600px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .popup-header {
    display: flex;
    justify-content: flex-end;
    background-color: #00000029;
  }
  
  .popup-content {
    padding: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 16px;
  }
  
  .popup-image {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000029;
    border-radius: 50%;
    padding : 20px
  }
  
  .popup-image img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
  
  .popup-text {
    margin-left: 16px;
  }
  
  .popup-text p {
    margin-bottom: 12px;
  }

  .request-id{
    color: blue;
  }
  