.createForm {
  width: 92%;
  margin-top: 2%;
  margin-left: 2%;
  flex-grow: 1;
  position: relative;
  flex-direction: column;
}

.strategic-engagement-radio{
  transform: scale(1.5); /* Adjust the scaling factor as needed */
  margin-right: 10px; /* Add some spacing between the radio button and the label */
  border-width: 4px;
}
.searchbarWidth {
  width: 96% !important;
  margin-left: 0px !important;
}

.input-label {
  font: normal normal 600 16px Segoe UI;
  letter-spacing: 0px;
}

.input-field {
  margin-bottom: 27px;
}

.leftGrid {
  /* border-right: 2px solid #BCBCBC; */
  padding-right: 8px;
}

.description {
  font: normal normal 600 16px Segoe UI;
  letter-spacing: 0px;
  color: #3B3B3B;
  width: 60%;
}

.dropdown-div {
  margin-top: 4%
}

.speaker-involvement-note {
  margin-top: 10px;
  font: normal normal normal 14px Segoe UI;
  letter-spacing: 0px;
}

.dropdown-label {
  margin-bottom: 10px;
  font: normal normal 600 14px Segoe UI;
  letter-spacing: 0px;
  color: #0E0E0E;
}

.dropdown-box {
  font: normal normal normal 16px Segoe UI !important;
  letter-spacing: 0px !important;
  color: #2D323A !important;
}

/* card css */
.content-subtitle {
  margin-bottom: 16px;
  font: normal normal normal 16px Segoe UI;
  letter-spacing: 0px;
  color: #3B3B3B;
}

.card {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  padding: 5px;
  border-left: 8px solid #00ADEF;
  background-color: #f7f7f7 !important;
  border-bottom: none;
  box-shadow: none;
  /* height : 1px; */
  /* Remove the box shadow */
}

.card-image {
  width: 100px !important;
  margin-right: 3%;
  margin-left: 3%;
}

.card-content {
  flex: 1;
}

.card-title {
  font: normal normal 600 20px Segoe UI;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 0px;
}

.card-subtitle {
  font: normal normal normal 16px Segoe UI;
  letter-spacing: 0px;
  color: #3B3B3B;
}

.cardContainear {
  margin-top: 56px;
}

.rightGrid {
  border: none;
}

.switchClass {
  padding-top: 3%;
  padding-left: 8%;
  align-items: center;
  justify-content: center;
}

.rightGridDiv {
  padding-left: 4%;
}

.dividerClass {
  margin-top: 20px;
}

.sub-description {
  margin-top: 20px;
  font: normal normal normal 16px Segoe UI;
  letter-spacing: 0px;
  color: #3B3B3B;
}

.searchContainer {
  display: flex;
  align-items: center;
  border: 2px solid;
  border-color: #A7A7A7;
  border-radius: 2px;
  padding: 8px;
  height: 20px;
  margin-top: 23px;
}

.searchInput {
  flex: 1;
  margin-left: 8px;
}

.search-icon {
  color: #2088FF;
}

.searchInput::placeholder {
  font: normal normal normal 16px Segoe UI;
  letter-spacing: 0px;
  color: #3B3B3B;
}

.active-directory-user {
  margin-top: 4%;
  height: 12rem;
  width: 100%;
  overflow: auto;
}

/* user card css */
.active-user-card {
  display: flex;
  align-items: center;
  border: none;
  transition: background-color 0.3s;
  cursor: pointer;
  width: 100%;
}

.active-user-card:hover {
  background-color: lightgray;
}

.active-user-card.active-user-selected {
  background-color: #00ADEF;
}

.active-user-image-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 5px;
  margin-top: 5px
}

.active-user-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.active-user-card-content {
  padding-left: 10%;
  flex-grow: 1;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}

.active-user-card-title {
  font: normal normal 600 16px Segoe UI;
  letter-spacing: 0px;
  color: #1B1919;
}

.active-user-card-role {
  font: normal normal normal 12px Segoe UI;
  letter-spacing: 0px;
  color: #606060;
}

.active-user-card-description {
  font: normal normal normal 12px Segoe UI;
  letter-spacing: 0px;
  color: #606060;
}

.active-user-card-title.selectedCard {
  font: normal normal 600 16px Segoe UI;
  letter-spacing: 0px;
  color: #ffffff;
}

.active-user-card-role.selectedCard {
  font: normal normal normal 12px Segoe UI;
  letter-spacing: 0px;
  color: #ffffff;
}

.active-user-card-description.selectedCard {
  font: normal normal normal 12px Segoe UI;
  letter-spacing: 0px;
  color: #ffffff;
}

.active-user-icon-container {
  display: flex;
  justify-content: center;
  margin-right: 5%;

}

.active-user-icon {
  color: #BDBDBD;
  /* background-color: #BDBDBD; */
  border-radius: 50%;
  border: 2PX SOLID #BDBDBD;
}

/* settings */
.MuiFormControl-root {
  width: 100%;
}

.MuiTextField-root {
  margin-bottom: 10px;
}

.MuiButton-root {
  margin-right: 10px;
}

input.MuiInputBase-input {
  height: 13px;
}
.executive-speaker-radio-button{
  display: flex;
  flex-direction: row;
}
textarea.MuiInputBase-input {
  height: 120px !important;
}

.MuiFormControlLabel-label {
  font: normal normal normal 16px Segoe UI !important;
  letter-spacing: 0px !important;
  color: #3B3B3B !important;
}

input[type='radio'] {
  accent-color: #1976d2;
}